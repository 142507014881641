import { getAuth, signInWithCustomToken } from "@firebase/auth";
import { Button, Container, Link, Stack, TextField } from '@mui/material';
import { Logo } from 'fni-components-next';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { coreActions } from '../../redux/CoreSlice/core';
import { authActions, authSelectors } from '../modules/auth';
import getBrowserFingerprint from '../services/BrowserFingerprint';
import MfaModal from './MfaModal';

export default function LoginPage() {

    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const auth = getAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { code } = useParams();
    const [params] = useSearchParams();

    const mfaState = useSelector(authSelectors.mfaState);

    const [browserFingerprint, setBrowserFingerprint] = useState('');

    useEffect(() => {
        getBrowserFingerprint().then(fingerprint => {
            setBrowserFingerprint(fingerprint);
        });
    }, []);

    useEffect(() => {
        if (code) {
            localStorage.setItem('code', code);
            dispatch(coreActions.setCode(code));
        }
    }, []);

    const handleClose = () => {
        dispatch(authActions.resetMfaState());
    };

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        dirty,
        isSubmitting,
    } = useFormik({
        initialValues: { email: '', password: '', remember: true },
        validationSchema: yup.object({
            email: yup.string().email('Must be valid email').required('Required'),
            password: yup.string().required('Required'),
            verificationCode: yup.string().trim()
                .matches(/^[0-9]{6}$/, 'Must be 6 digits'),
            remember: yup.boolean()
        }),
        onSubmit: (values, { setFieldValue, setSubmitting }) => {
            const username = values.email;
            const password = values.password;
            const mfaCode = values.verificationCode;
            const fingerprint = browserFingerprint;
            const remember = values.remember;

            dispatch(authActions.openMfaVerifyModal(false));

            dispatch(authActions.signInUser({ username, password, mfaCode, fingerprint, remember, code }))
                .unwrap()
                .then(authResult => {
                    const token = authResult.data.token;

                    if (process.env.REACT_APP_DISABLE_DOUBLE_LOGIN == 'true') {
                        token.claims?.isFNIAdmin ? navigate('/tenants/list/tenants') : navigate('/');
                    } else if (code) {
                        signInWithCustomToken(auth, token)
                            .then(response => {
                                if (!response.error) {
                                    dispatch(authActions.getRedirect(code))
                                        .unwrap()
                                        .then(response => {
                                            const { redirectUrl, isRegistered, isMigrated, currentTenant } = response.data;

                                            if (!redirectUrl) {
                                                dispatch(authActions.signOutUser());
                                            }
                                            dispatch(authActions.setCurrentTenant(currentTenant));

                                            dispatch(authActions.setUserIsRegistered(isRegistered));

                                            dispatch(authActions.setTenantIsMigrated(isMigrated));

                                            if (redirectUrl.includes('http')) {
                                                const redirect = new URL(redirectUrl);
                                                const refnum = params.get('REFNUM');
                                                params.forEach((v,k) => {
                                                    if (k === 'destination') {
                                                        if (refnum) {
                                                            redirect.searchParams.append(k, `/${v}` + (v.includes('?') ? '&' : '?') + `REFNUM=${refnum}`)
                                                        } else {
                                                            redirect.searchParams.append(k, `/${v}`);
                                                        }
                                                    } else {
                                                        redirect.searchParams.append(k, v);
                                                    }
                                                });
                                                window.location.href = redirect;
                                            } else {
                                                navigate(redirectUrl);
                                            }
                                        })
                                        .catch(() => {
                                            dispatch(authActions.signOutUser())
                                                .then(() => {
                                                    dispatch(authActions.setErrorMessage('Login Error'));
                                                    navigate('/auth/login/' + code);
                                                });
                                        });
                                };
                            });
                    }

                })
                .catch(error => {
                    switch (error.message) {
                        case 'USER_LOCKED':
                            dispatch(authActions.setErrorMessage('Your user is locked, please wait a few minutes and try again'));
                            setFieldValue('password', '', false);
                            break;
                        case 'INVALID_CREDENTIALS':
                            dispatch(authActions.setErrorMessage('Incorrect username or password'));
                            setFieldValue('password', '', false);
                            break;
                        case 'MFA_INVALID_CODE':
                            setFieldValue('verificationCode', '', false);
                            dispatch(authActions.setErrorMessage('Invalid MFA Code'));
                        case 'MFA_CODE_REQUIRED':
                            dispatch(authActions.openMfaVerifyModal(true));
                            break;
                        case 'MFA_NOT_ENABLED':
                            signInWithCustomToken(auth, error.token)
                                .then(response => {
                                    const timer = setTimeout(() => {
                                        if (!response.error) navigate(`/auth/register${params.size ? `?${params.toString()}` : ''}`);
                                    }, 1000);
                                    return () => clearTimeout(timer);
                                });

                            break;
                        case 'FORCE_CHANGE_PASSWORD':
                            signInWithCustomToken(auth, error.token)
                                .then(response => {
                                    const timer = setTimeout(() => {
                                        if (!response.error) {
                                            navigate(`/auth/force-change-password${params.size ? `?${params.toString()}` : ''}`, { state: { oldPassword: values.password } });
                                        }
                                    }, 1000);
                                    return () => clearTimeout(timer);
                                });
                            break;

                    }
                })
                .finally(() => setSubmitting(false));
        },
    });

    const showEmailError = Boolean(errors.email && touched.email && dirty);

    const showPasswordError = Boolean(errors.password && touched.password && dirty);

    const showMfaError = Boolean(errors.verificationCode && touched.verificationCode && dirty);

    return (
        <Container sx={styles} maxWidth='md'>

            <Logo code={code} width='35%' />

            <form onSubmit={handleSubmit} style={{ width: '100%' }}>

                <Stack spacing={2} padding={3} margin={3}>
                    <TextField
                        name='email'
                        label='Email'
                        autoFocus
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={showEmailError}
                        helperText={showEmailError ? errors.email : null}
                    />

                    <TextField
                        name='password'
                        label='Password'
                        type='password'
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={showPasswordError}
                        helperText={showPasswordError ? errors.password : null}
                    />

                    <Stack flexDirection={{ xs: 'column', sm: 'row' }}
                        justifyContent={{ xs: 'center', sm: 'space-between' }} >

                        <Link onClick={() => navigate('/auth/forgot-password')}>Forgot Password?</Link>

                        <Button disabled={isSubmitting} type='submit'>Login</Button>

                    </Stack>

                    <MfaModal
                        open={mfaState.modal.verifyOpen}
                        onClose={handleClose}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        values={values}
                        handleSubmit={handleSubmit}
                        error={showMfaError}
                        helperText={showMfaError ? errors.verificationCode : null}
                    />

                </Stack>
            </form>

        </Container>
    );
}
