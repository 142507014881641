import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import MfaTypes from '../MfaTypes';
import { authSelectors } from '../modules/auth';

export default function MfaModal(props) {
    const { mfaType, recipient } = useSelector(authSelectors.mfaState);

    const dialogText = {
        [MfaTypes.TOTP]: "Enter the 6-digit security code from your authenticator app.",
        [MfaTypes.SMS]: `Enter the 6-digit code sent to phone number: ${recipient}`,
        [MfaTypes.EMAIL]: `Enter the 6-digit code sent to email: ${recipient}`,
    }

    const {
        open,
        onClose,
        onChange,
        onBlur,
        values,
        hideRemember,
        error,
        helperText,
        handleSubmit,
    } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm Multi-Factor Authentication Code</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: '1rem' }}>
                    {dialogText[mfaType]}
                </DialogContentText>
                <TextField
                    autoFocus
                    name="verificationCode"
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyPress={e => { if (e.key === 'Enter') handleSubmit(); }}
                    value={values.verificationCode || ''}
                    error={error}
                    helperText={helperText}
                    label="MFA Code"
                    type="text"
                    variant="standard"
                    autoComplete="off"
                />
                {!hideRemember &&
                    <DialogContentText sx={{ marginTop: '1rem', fontSize: 'small' }}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={values.remember} onChange={onChange} name='remember' />
                            }
                            label="Remember this device"
                        />
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button type="submit" onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}
